import React from "react";
import AcUnitIcon from '@material-ui/icons/AcUnit';
import WavesIcon from '@material-ui/icons/Waves';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import { Chip } from "@material-ui/core";

export default function TemperatureLabel({
  temperature,
  size="small"
}) {
  return (
    <>
      {temperature === 'hot' && <Chip size={size} style={{ background: 'rgb(230, 69, 69)', color: '#fff' }} label="Quente" icon={<WhatshotIcon style={{ color: '#fff' }} />} />}
      {temperature === 'warm' && <Chip size={size} style={{ background: 'orange', color: '#fff' }} label="Morno" icon={<WavesIcon style={{ color: '#fff' }} />} />}
      {temperature === 'cold' && <Chip size={size} style={{ background: 'rgb(158 155 226)', color: '#fff' }} label="Frio" icon={<AcUnitIcon style={{ color: '#fff' }} />} />}
    </>
  );
}