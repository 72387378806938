const WorkflowConfig = {
  defaultWorkflow: 'lead_client',
  workflows: {
    lead_client: {
      id: 'lead_client',
      name: 'Comercial',
      labelNew: 'Criar nova oportunidade',
      forms: {
        create: true,
        update: true,
      },
      defaultBoard: 'doing',
      boards: {
        doing: {
          id: 'doing',
          name: 'Em andamento',
          steps: [
            { id: 'lead_qualification', label: 'Qualificação do lead' },
            { id: 'visit', label: 'Visita' },
            { id: 'property_qualification', label: 'Qualificação do imóvel' },
            { id: 'project_creation', label: 'Criação do projeto', headerSum: ['amount', 'loose_amount'] },
            { id: 'project_presentation', label: 'Apresentação', headerSum: ['amount', 'loose_amount'] },
            { id: 'closing', label: 'Fechamento', headerSum: ['amount', 'loose_amount'] },
          ]
        },
        future_contact: {
          id: 'future_contact',
          name: 'Contato futuro',
          steps: [
            { id: 'future_contact', label: 'Contato futuro', headerSum: ['amount', 'loose_amount'] },
          ]
        },
        gain: {
          id: 'gain',
          name: 'Ganhos',
          steps: [
            { id: 'gain', label: 'Ganho', headerSum: ['amount', 'loose_amount'] },
          ]
        },
        lost: {
          id: 'lost',
          name: 'Perdidos',
          steps: [
            { id: 'lost', label: 'Perdido', headerSum: ['amount', 'loose_amount'] },
          ]
        }
      }
    },

    after_sale: {
      id: 'after_sale',
      name: 'Pós venda',
      forms: {
        
      },
      defaultBoard: 'doing',
      boards: {
        doing: {
          id: 'doing',
          name: 'Em andamento',
          steps: [
            { id: 'contract', label: 'Contrato' },
            { id: 'project_conference', label: 'Conferência do projeto' },
            { id: 'order', label: 'Pedido' },
            { id: 'production', label: 'Produção' },
            { id: 'external_purchases', label: 'Compras externas' },
            { id: 'pre_install', label: 'Pré-montagem' },
            { id: 'install', label: 'Montagem' },
            { id: 'final_delivery', label: 'Entrega final' },
            { id: 'quality', label: 'Qualidade' },
          ]
        },
        done: {
          id: 'done',
          name: 'Concluídos',
          steps: [
            { id: 'done', label: 'Concluído' },
          ]
        },
      }
    },

    task: {
      id: 'task',
      name: 'Tarefas',
      labelNew: 'Criar tarefa',
      forms: {
        create: true,
      },
      defaultBoard: 'doing',
      boards: {
        doing: {
          id: 'doing',
          name: 'Em andamento',
          steps: [
            { id: 'todo', label: 'Para fazer' },
            { id: 'doing', label: 'Fazendo' },
            { id: 'impediment', label: 'Impedida' },
          ]
        },
        done: {
          id: 'done',
          name: 'Concluídos',
          steps: [
            { id: 'done', label: 'Concluído' },
            { id: 'cancel', label: 'Cancelados' },
          ]
        },
      }
    }
  },
  
};

export const translateWorkflowName = (workflow) => {
  const currentWorkflow = WorkflowConfig.workflows[workflow];

  if (currentWorkflow) {
    return currentWorkflow.name;
  } else {
    return workflow;
  }
};

export const translateWorkflowStep = (workflow, status) => {
  let label = status;

  const currentWorkflow = WorkflowConfig.workflows[workflow];

  if (!currentWorkflow) {
    return label;
  }

  Object.keys(currentWorkflow.boards).forEach(board => {
    const found = currentWorkflow.boards[board].steps.find(step => step.id === status);

    if (found) {
      label = found.label;
    }
  });

  return label;
};

export const getStepsFromWorkflow = (workflow) => {
  const currentWorkflow = WorkflowConfig.workflows[workflow];
  
  if (!currentWorkflow) return [];

  let steps = [];

  Object.keys(currentWorkflow.boards).forEach(board => {
    steps = [...steps, ...currentWorkflow.boards[board].steps]
  });

  return steps;
};

export default WorkflowConfig;