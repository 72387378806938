import { Checkbox, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Controller } from "react-hook-form";
import React from "react";
import FieldErrors from "./field-errors";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import EventIcon from '@material-ui/icons/Event';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import FormService from "../../services/form";

const checkboxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const selectIcon = (option, startAdornment) => {
  const data = [];

  if (option.id === 'city') {
    data.push(<LocationCityIcon />);
  } else if (option.id === 'estimated_due_date') {
    data.push(<EventIcon />);
  } else if (option.id === 'budget') {
    data.push(<MonetizationOnIcon />);
  } if (option.id === 'origin') {
    data.push(<EmojiPeopleIcon />);
  } if (option.id === 'environment') {
    data.push(<HomeWorkIcon />);
  }

  if (!startAdornment) {
    return data;
  }

  return [...data, ...startAdornment];
};

const AutocompleteField = ({
  field,
  name,
  option,
  setValue,
  errorsForm,
  errorsApi,
  multiple,
  checkbox,
  onChange,
  variant,
  required
}) => <>
    <Autocomplete {...field}
      id={`select_option_${option.id}_${name}`}
      multiple={multiple}
      disableCloseOnSelect={checkbox}
      options={option.values}
      getOptionLabel={(option) => option.name}
      onChange={(_event, newValue) => {
        if (Array.isArray(newValue)) {
          setValue(name, newValue.map(v => v.id));
        } else {
          setValue(name, newValue?.id);
        }

        if (onChange) {
          onChange(_event, newValue);
        }
      }}
      renderOption={(option, { selected }) => {
        return (
          <>
            {checkbox && (
              <Checkbox
                icon={checkboxIcon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            )}
            {option.name}
          </>
        );
      }}
      renderInput={(params, value) => {
        return (
          <>
            <TextField {...params}
              label={
                <>
                  {option.name}{required && '*'}
                </>
              }
              variant={variant}
              margin="normal"
              fullWidth
              error={FormService.hasError(name, errorsForm, errorsApi)}
              inputProps={{
                ...params.inputProps,
                startAdornment: selectIcon(option, params.InputProps.startAdornment),
                autoComplete: 'off',
              }}
            />
          </>
        );
      }

      }
      loadingText="Carregando..."
      noOptionsText="Sem opções"
      openText="Abrir"
      closeText="Fechar"
      clearText="Limpar"
    />
    <FieldErrors name={name} errorsForm={errorsForm} errorsApi={errorsApi} />
  </>;

export default function SelectOption({
  name,
  option,
  control,
  setValue,
  errorsForm,
  errorsApi,
  multiple,
  required,
  checkbox,
  onChange,
  defaultValue,
  variant = 'outlined'
}) {

  return (
    <>
      {control ? (
        <Controller
          name={name}
          control={control}
          rules={{ required: required }}
          defaultValue={defaultValue}
          render={
            ({ field }) => {
              return AutocompleteField({
                field,
                name,
                option,
                control,
                setValue,
                errorsForm,
                errorsApi,
                multiple,
                checkbox,
                onChange,
                variant,
                required
              });
            }
          }
        />
      ) : (
        AutocompleteField({
          name,
          option,
          control,
          setValue,
          errorsForm,
          errorsApi,
          multiple,
          checkbox,
          onChange,
          variant,
          required
        })
      )}
    </>
  );
}
